<template>
  <div>
    <ave-tabset :navs="navs" :active="tabsetActive" @change="toggle" />
    <div class="ave-icon-input">
      <span class="aico-app-search form-control-back" />
      <input type="text" class="form-control" v-model="searchValue" @keyup.enter="initData(false)">
    </div>
    <div style="height:12px;" />
    <div>
      <table class="table">
        <tbody>
          <template v-for="(item, index) in data">
            <tr :key="'tr1-' + index">
              <td>
                {{
                  item.createTime === undefined
                    ? ""
                    : item.createTime.substring(0, 10)
                }}
              </td>
              <td>
                <span>订单号:{{ item.orderNo }}</span>
                <span class="dialog-btn-middle" />
                <span>开发者联系方式:{{ item.devEmail }}</span>
              </td>
              <td colspan="4" />
            </tr>
            <tr :key="'tr2-' + index" class="tr-main">
              <td width="10%" @click="chooseCheckbox(item.orderId)">
                <span
                  class="orderlist-checkbox"
                  :class="
                    checkedbox.indexOf(item.orderId) > -1
                      ? 'checkbox-checked'
                      : 'checkbox-unchecked'
                  "
                />
              </td>

              <td width="50%">
                <ul class="orderlist-ul">
                  <li>
                    <span class="order-detail-title">翻译内容</span>
                    <span>{{ item.applicationName }}</span>
                  </li>
                  <li>
                    <span class="order-detail-title">源语言:</span>
                    <span>{{ item.sourceLang.languageNameCh }}</span>
                    <span class="order-detail-title">目标语言:</span>
                    <span>{{ item.targetLanguage }}</span>
                  </li>
                </ul>
              </td>
              <td width="20%">
                <span class="order-detail-title">总价(含税)</span>
                <span>{{ item.cost }}</span>
                <span>RMB</span>
              </td>
              <td width="5%">
                <div class="s_orderlist-user">
                  <span>{{ showStatus[item.status] }}</span>
                </div>
              </td>
              <td width="10%">
                <div style="height:4px;" />
                <div>
                  <span @click="toOrderDetail(item.orderId)" class="orderlist-process">处理进展</span>
                </div>
              </td>
              <td width="5%">
                <span
                  class="aico-chevron-up orderlist-dropdown"
                  v-if="spreadItems.indexOf(item.orderId) > -1"
                  @click="spread(item.orderId)"
                />
                <span
                  class="aico-chevron-down orderlist-dropdown"
                  v-if="spreadItems.indexOf(item.orderId) < 0"
                  @click="spread(item.orderId)"
                />
              </td>
            </tr>
            <tr :key="'tr3-' + index" v-if="spreadItems.indexOf(item.orderId) > -1">
              <td colspan="6">
                <div class="orderlist-detail">
                  {{ item.applicationName }}-{{ item.appIntroduce }} -{{
                    item.appIntroduceInshort
                  }}
                  -{{ item.appFeatures }}
                </div>
              </td>
            </tr>
          </template>
          <tr v-if="data.length < 1" class="tr-main">
            <td colspan="6" class="orderlist-tr-nodata">
              --- no data ---
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <template v-if="data.length > 0">
          <div style="display:inline-block;padding:2px 8px 0 8px;">
            <span
              class="orderlist-checkbox"
              :class="checkall ? 'checkbox-checked' : 'checkbox-unchecked'"
              @click="clickAll"
            />
          </div>
          <div style="display:inline-block;">
            <span class="checkbox-checkAll-text">全选</span>
          </div>
        </template>
        <span style="width:85%;display:inline-block;" />
        <span class="orderlist-back-btn" @click="back">返回</span>
      </div>
    </div>
    <div class="orderlist-bottom">
      <span style="width:68%;display:inline-block;" />
      <ave-pager :pager="pager" @change="onPage($event)" :show-page-size="false" />
    </div>

    <div>
      <ave-toasts :msgs="toasts" :life="3000" />
    </div>

    <!-- 确认弹出窗 -->
    <el-dialog :visible.sync="dialogVisible" width="300px">
      <div class="dialog-div-main">
        确定删除订单？
      </div>
      <div style="padding-left:25%;">
        <span class="dialog-btn" @click="deleteOrders">确定</span>
        <span class="dialog-btn-middle" />
        <span class="dialog-btn" @click="dialogVisible = false">取消</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Quality',
  data() {
    return {
      navs: ['全部', '待处理', '处理中', '已完成'],
      tabsetActive: '',
      data: [],
      searchValue: '', // 搜索项
      checkall: false,
      checkedbox: [],
      toasts: [],
      dialogVisible: false,
      pager: {
        total: 23,
        index: 0,
        pageSize: 10
      },
      spreadItems: [], // 当前展开详情的订单
      showStatus: {
        init: '待确认',
        nopay: '待支付',
        pay: '已支付',
        translate: '处理中',
        appraise: '待评价',
        end: '已完成'
      }
    };
  },
  created() {},
  mounted() {
    this.tabsetActive = this.navs[0];
    // this.initData();
  },
  methods: {
    initData(pstatus) {
      const $this = this;
      const params = {
        start: $this.pager.index * $this.pager.pageSize,
        length: $this.pager.pageSize,
        type: 's',
        searchValue:
          $this.searchValue.length > 32
            ? $this.searchValue.substring(0, 32)
            : $this.searchValue
      };
      if (pstatus) {
        params.status = pstatus;
      }
      this.$http
        .post('/multilingualOrder/order/list', params)
        .then(response => {
          const res = response.data;
          if (res.status === 'success') {
            $this.data = res.data.data;
            $this.pager.total = res.data.total;
            $this.data.forEach(e => {
              const arr = [];
              e.targetLangList.forEach(el => {
                arr.push(el.languageNameCh);
              });
              e.targetLanguage = arr.join(this.$t('ctp.comma'));
            });
          } else {
            $this.showtoasts('danger', res.data);
          }
        })
        .catch(error => {
          $this.showtoasts('danger', error.message);
        });
    },
    onPage() {
      this.checkall = false;
      this.initData();
    },
    clickAll() {
      this.checkall = !this.checkall;
      if (this.checkall) {
        this.checkedbox = [];
        this.data.forEach(el => {
          this.checkedbox.push(el.orderId);
        });
      } else {
        this.checkedbox = [];
      }
    },
    chooseCheckbox(orderId) {
      if (this.checkedbox.indexOf(orderId) > -1) {
        const arr = this.checkedbox.filter((value) => value !== orderId);
        this.checkedbox = arr;
      } else {
        this.checkedbox.push(orderId);
      }
      this.checkall = this.data.length === this.checkedbox.length;
    },
    back() {
      this.$router.push({ path: '/' });
    },
    toOrderDetail(orderid) {
      this.$router.push({ path: '/odetail', query: { id: orderid } });
    },
    toggle($event) {
      this.tabsetActive = $event;
      if ($event === '全部') {
        this.initData();
      } else if ($event === '待处理') {
        this.initData('pay');
      } else if ($event === '处理中') {
        this.initData('translate');
      } else if ($event === '已完成') {
        this.initData('end');
      }
    },
    showtoasts(type, message) {
      this.toasts = [{ type, summary: '提示', detail: message }];
    },
    spread(orderid) {
      if (this.spreadItems.indexOf(orderid) > -1) {
        const arr = this.spreadItems.filter((value) => value !== orderid);
        this.spreadItems = arr;
      } else {
        this.spreadItems.push(orderid);
      }
    }
  }
};
</script>

<style scoped>
.orderlist-bottom {
  padding-top: 24px;
}
.orderlist-bottom span {
  display: inline-block;
}
.orderlist-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}
.checkbox-unchecked {
  border: 1px solid grey;
  background-color: #fff;
}
.checkbox-checked {
  border: 0;
  background-color: #5762ff;
}
.table {
  border-top: 0;
}
.table tr.tr-main {
  border: 1px solid lightgrey;
}
.table tr.tr-main td {
  height: 64px;
}
.table tr td:first-child span {
  margin-top: 16px;
}
.checkbox-checkAll-text {
  width: 40px;
}
.orderlist-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.orderlist-process {
  cursor: pointer;
  padding: 2px 8px;
  text-align: center;
  border-radius: 4px;
  background-color: #5762ff;
  color: #fff;
}
.order-detail-title {
  font-size: 16px;
  font-weight: 600;
}
.dialog-div-main {
  padding: 0 0 12px 23%;
  font-size: 20px;
}
.dialog-btn {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  background-color: #5762ff;
}
.dialog-btn-middle {
  display: inline-block;
  width: 10%;
}
.s_orderlist-user {
  padding-top: 12%;
}
.ave-icon-input {
  width: 20%;
}
.orderlist-back-btn {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid lightgray;
  color: #fff;
  background-color: #5762ff;
  cursor: pointer;
}
.orderlist-tr-nodata {
  text-align: center;
  color: #666;
  line-height: 48px;
}
.orderlist-dropdown {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  line-height: 48px;
}
.orderlist-detail {
  height: 128px;
  background-color: lightcyan;
}
.table > tbody > tr > td {
  border-top: 0;
}
</style>
